// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarPlaylist = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M1 3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v10h-2V3H3v18h8v2H3a2 2 0 0 1-2-2V3Z" fill="#F4F6F8" />
    <path d="M15 23V13l8 5-8 5Z" fill="#F4F6F8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19 8H5V6h14v2ZM11 13H5v-2h6v2ZM11 18H5v-2h6v2Z" fill="#F4F6F8" />
  </svg>
))
SvgSidebarPlaylist.displayName = 'SvgSidebarPlaylist'
const Memo = memo(SvgSidebarPlaylist)
export { Memo as SvgSidebarPlaylist }
