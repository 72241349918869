// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIllustrativeEdit = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M32.293 7.679a1 1 0 0 1 1.4.014l4.585 4.585a1 1 0 0 1-.014 1.429l-2.114 2.029-6-6 2.143-2.057ZM28.707 11.121l6 6L14 37H8v-6l20.707-19.879ZM8 41h32v-2H8v2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgIllustrativeEdit.displayName = 'SvgIllustrativeEdit'
const Memo = memo(SvgIllustrativeEdit)
export { Memo as SvgIllustrativeEdit }
