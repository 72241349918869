// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoAppleOnLight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M19.622 8.829c-.116.09-2.165 1.244-2.165 3.81 0 2.969 2.607 4.019 2.685 4.045-.012.064-.414 1.438-1.374 2.838-.856 1.232-1.75 2.462-3.11 2.462-1.36 0-1.71-.79-3.281-.79-1.53 0-2.074.816-3.318.816-1.244 0-2.113-1.14-3.11-2.54-1.157-1.644-2.09-4.198-2.09-6.623 0-3.888 2.527-5.95 5.016-5.95 1.322 0 2.424.868 3.254.868.79 0 2.022-.92 3.526-.92.57 0 2.619.052 3.967 1.984Zm-4.68-3.63c.621-.739 1.061-1.763 1.061-2.787a1.92 1.92 0 0 0-.038-.402c-1.012.038-2.216.674-2.942 1.516-.57.648-1.102 1.672-1.102 2.71 0 .157.026.313.038.363.064.012.168.026.272.026.908 0 2.05-.608 2.71-1.427Z"
      fill="#000"
    />
  </svg>
))
SvgLogoAppleOnLight.displayName = 'SvgLogoAppleOnLight'
const Memo = memo(SvgLogoAppleOnLight)
export { Memo as SvgLogoAppleOnLight }
