// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsCastOutline = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H11v-2h10V5H3v6H1V5Zm6 16a6 6 0 0 0-6-6v-2a8 8 0 0 1 8 8H7Zm-6-2a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4v2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsCastOutline.displayName = 'SvgControlsCastOutline'
const Memo = memo(SvgControlsCastOutline)
export { Memo as SvgControlsCastOutline }
