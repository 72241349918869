// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionUnread = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6Zm5.39 5.792a1 1 0 0 0 1.22 0l3.2-2.466a1 1 0 1 0-1.22-1.584L8 9.737 5.41 7.742a1 1 0 1 0-1.22 1.584l3.2 2.466Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionUnread.displayName = 'SvgActionUnread'
const Memo = memo(SvgActionUnread)
export { Memo as SvgActionUnread }
