// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionFlag = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1H2v14h2V8h3.172a2 2 0 0 1 1.414.586l.828.828a2 2 0 0 0 1.414.586H14V3h-3.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 7.172 1H4Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionFlag.displayName = 'SvgActionFlag'
const Memo = memo(SvgActionFlag)
export { Memo as SvgActionFlag }
