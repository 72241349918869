// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesEntertainment = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.515 2.34A2 2 0 0 1 15 4.28V6h-2V4.28L6.825 5.826 5.28 12H9v8.453L18.707 10H13V8h5.707c1.746 0 2.654 2.081 1.465 3.36l-9.706 10.454C9.229 23.146 7 22.271 7 20.454V14H5.28a2 2 0 0 1-1.94-2.485L4.884 5.34A2 2 0 0 1 6.34 3.884l6.175-1.544Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesEntertainment.displayName = 'SvgCategoriesEntertainment'
const Memo = memo(SvgCategoriesEntertainment)
export { Memo as SvgCategoriesEntertainment }
