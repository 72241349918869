// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionRevenueShare = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M9 4a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" fill="#fff" />
    <path
      d="M1 7h2v8H1V7ZM10.275 8.793 4 7v6l4.166 1.666a4.667 4.667 0 0 0 3.82-.159L15 13v-.5a1.5 1.5 0 0 0-1.5-1.5h-2.669c-.193 0-.385.016-.575.048l-1.659.276a2.5 2.5 0 0 1-2.178-.698l-.273-.272a.5.5 0 0 1 .708-.708l.272.273a1.5 1.5 0 0 0 1.307.419l1.658-.277c.245-.04.492-.061.74-.061H11v-.246a1 1 0 0 0-.725-.961Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionRevenueShare.displayName = 'SvgActionRevenueShare'
const Memo = memo(SvgActionRevenueShare)
export { Memo as SvgActionRevenueShare }
