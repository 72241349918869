// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesScienceAndTechnology = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11a7 7 0 0 1 14 0v.338a5 5 0 0 1-.713 2.573l-1.29 2.15a7 7 0 0 0-.997 3.6V20h2v-.338a5 5 0 0 1 .712-2.573l1.29-2.15a7 7 0 0 0 .998-3.6V11a9 9 0 0 0-18 0v1.667L2.4 14.8C1.411 16.119 2.352 18 4 18v2a2 2 0 0 0 2 2 9.001 9.001 0 0 0 8.961-8.157A3.001 3.001 0 0 0 14 8a3 3 0 0 0-1.047 5.812A7.001 7.001 0 0 1 6 20v-4H4l1.8-2.4.2-.267V11Zm7 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesScienceAndTechnology.displayName = 'SvgCategoriesScienceAndTechnology'
const Memo = memo(SvgCategoriesScienceAndTechnology)
export { Memo as SvgCategoriesScienceAndTechnology }
