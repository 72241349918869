// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsSoundOn = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="m6 9 4-5h2v16h-2l-4-5H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h3Z" fill="#F4F6F8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12a6 6 0 0 0-6-6V4a8 8 0 1 1 0 16v-2a6 6 0 0 0 6-6Z"
      fill="#F4F6F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 12a2 2 0 0 0-2-2V8a4 4 0 0 1 0 8v-2a2 2 0 0 0 2-2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsSoundOn.displayName = 'SvgControlsSoundOn'
const Memo = memo(SvgControlsSoundOn)
export { Memo as SvgControlsSoundOn }
