// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsSoundLowVolume = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4 6 9H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h3l4 5h2V4h-2Zm4 6a2 2 0 1 1 0 4v2a4 4 0 0 0 0-8v2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsSoundLowVolume.displayName = 'SvgControlsSoundLowVolume'
const Memo = memo(SvgControlsSoundLowVolume)
export { Memo as SvgControlsSoundLowVolume }
