// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoWhatsAppOnDark = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m2.342 21.66 1.36-4.958a9.57 9.57 0 0 1-1.279-4.783c0-5.277 4.298-9.57 9.57-9.57 2.562 0 4.963.999 6.77 2.805a9.51 9.51 0 0 1 2.8 6.77c0 5.277-4.298 9.57-9.57 9.57h-.005a9.575 9.575 0 0 1-4.573-1.165L2.342 21.66Z"
      fill="url(#paint0_linear_603_427)"
    />
    <path
      d="m2 22.008 1.407-5.134a9.872 9.872 0 0 1-1.321-4.954c0-5.467 4.45-9.912 9.916-9.912a9.837 9.837 0 0 1 7.013 2.904 9.829 9.829 0 0 1 2.9 7.013c0 5.467-4.45 9.912-9.917 9.912h-.005a9.92 9.92 0 0 1-4.74-1.208L2 22.008Zm5.5-3.176.3.18a8.261 8.261 0 0 0 4.193 1.151h.005c4.54 0 8.238-3.694 8.238-8.238a8.2 8.2 0 0 0-2.41-5.829 8.176 8.176 0 0 0-5.828-2.415c-4.545 0-8.244 3.694-8.244 8.239 0 1.554.433 3.07 1.26 4.383l.195.314-.827 3.033 3.118-.818Z"
      fill="url(#paint1_linear_603_427)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52 7.77c-.185-.414-.38-.424-.555-.429-.143-.004-.31-.004-.476-.004a.912.912 0 0 0-.66.309c-.229.247-.866.846-.866 2.068 0 1.216.89 2.396 1.013 2.562.123.166 1.716 2.748 4.23 3.741 2.093.828 2.52.661 2.972.618.456-.043 1.464-.599 1.674-1.179.204-.58.204-1.074.142-1.179-.062-.104-.228-.166-.475-.29-.247-.123-1.464-.722-1.693-.808-.228-.08-.394-.123-.556.124-.166.247-.642.803-.784.97-.143.166-.29.185-.538.061-.247-.123-1.045-.385-1.992-1.23-.736-.657-1.235-1.47-1.378-1.717-.143-.247-.014-.38.11-.504.109-.11.246-.29.37-.433.124-.142.166-.247.247-.413.081-.167.043-.31-.019-.433-.066-.119-.546-1.345-.765-1.835Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="paint0_linear_603_427"
        x1={11.956}
        y1={21.66}
        x2={11.956}
        y2={2.349}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20B038" />
        <stop offset={1} stopColor="#60D66A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_603_427"
        x1={11.956}
        y1={22.006}
        x2={11.956}
        y2={2.004}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F9F9" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
))
SvgLogoWhatsAppOnDark.displayName = 'SvgLogoWhatsAppOnDark'
const Memo = memo(SvgLogoWhatsAppOnDark)
export { Memo as SvgLogoWhatsAppOnDark }
