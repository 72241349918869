// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionChevronT = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8 6.414-5.293 5.293-1.414-1.414L8 3.586l6.707 6.707-1.414 1.414L8 6.414Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionChevronT.displayName = 'SvgActionChevronT'
const Memo = memo(SvgActionChevronT)
export { Memo as SvgActionChevronT }
