// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoRedditMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12.01c0 5.522-4.477 10-10 10s-10-4.478-10-10c0-5.524 4.477-10 10-10s10 4.476 10 10Zm-4.795-1.463c.807 0 1.462.655 1.462 1.462 0 .597-.363 1.111-.843 1.345.024.14.036.281.036.433 0 2.246-2.609 4.059-5.837 4.059-3.228 0-5.836-1.813-5.836-4.059 0-.152.012-.304.035-.444a1.462 1.462 0 0 1 .596-2.795c.387 0 .75.163 1.007.409 1.005-.737 2.397-1.193 3.953-1.24l.737-3.486a.285.285 0 0 1 .117-.163.292.292 0 0 1 .198-.035l2.421.514c.164-.35.515-.585.924-.585.573 0 1.041.468 1.041 1.041a1.042 1.042 0 0 1-2.082.047l-2.163-.456-.667 3.123c1.52.058 2.9.526 3.895 1.24.257-.258.608-.41 1.006-.41ZM9.708 12.01c-.574 0-1.041.468-1.041 1.041s.467 1.041 1.04 1.041c.574 0 1.041-.468 1.041-1.04 0-.574-.467-1.042-1.04-1.042Zm2.304 4.55c.397 0 1.754-.047 2.467-.76a.3.3 0 0 0 .024-.386.275.275 0 0 0-.386 0c-.456.444-1.404.608-2.094.608s-1.649-.164-2.093-.608a.275.275 0 0 0-.386 0 .275.275 0 0 0 0 .386c.702.701 2.07.76 2.468.76Zm1.24-3.509c0 .573.467 1.041 1.04 1.041.574 0 1.041-.468 1.041-1.04 0-.574-.467-1.042-1.04-1.042-.574 0-1.042.468-1.042 1.041Z"
      fill="#fff"
    />
  </svg>
))
SvgLogoRedditMonochrome.displayName = 'SvgLogoRedditMonochrome'
const Memo = memo(SvgLogoRedditMonochrome)
export { Memo as SvgLogoRedditMonochrome }
