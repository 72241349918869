// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionReply = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m4.828 8 3.586 3.586L7 13 1 7l6-6 1.414 1.414L4.828 6H8a7 7 0 0 1 7 7v2h-2v-2a5 5 0 0 0-5-5H4.828Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionReply.displayName = 'SvgActionReply'
const Memo = memo(SvgActionReply)
export { Memo as SvgActionReply }
