// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesComedy = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm-5 9H6v1a6 6 0 0 0 12 0v-1H7Zm5 5a4.002 4.002 0 0 1-3.874-3h7.748A4.002 4.002 0 0 1 12 16Zm-2-8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesComedy.displayName = 'SvgCategoriesComedy'
const Memo = memo(SvgCategoriesComedy)
export { Memo as SvgCategoriesComedy }
