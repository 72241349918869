// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionArrowLeft = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.828 8 5.293 5.293-1.414 1.414L1 8l6.707-6.707L9.12 2.707 3.828 8Z"
      fill="#F4F6F8"
    />
    <path d="M3 9V7h12v2H3Z" fill="#F4F6F8" />
  </svg>
))
SvgActionArrowLeft.displayName = 'SvgActionArrowLeft'
const Memo = memo(SvgActionArrowLeft)
export { Memo as SvgActionArrowLeft }
