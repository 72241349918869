// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoMessengerOnLight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.866c-5.281 0-9.375 3.868-9.375 9.093 0 2.733 1.12 5.095 2.944 6.727a.749.749 0 0 1 .252.534l.051 1.668a.75.75 0 0 0 1.053.663l1.86-.822a.748.748 0 0 1 .501-.036c.855.235 1.765.36 2.714.36 5.281 0 9.375-3.869 9.375-9.094S17.281 2.866 12 2.866Z"
      fill="url(#paint0_radial_603_409)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.37 14.621 2.754-4.37a1.406 1.406 0 0 1 2.034-.374l2.19 1.642c.2.151.477.15.678-.002l2.958-2.245c.394-.3.91.173.646.593l-2.754 4.369a1.406 1.406 0 0 1-2.034.375l-2.19-1.643a.562.562 0 0 0-.678.002l-2.958 2.245c-.395.3-.91-.173-.646-.592Z"
      fill="#fff"
    />
    <defs>
      <radialGradient
        id="paint0_radial_603_409"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.234 21.515) scale(20.4299)"
      >
        <stop stopColor="#09F" />
        <stop offset={0.61} stopColor="#A033FF" />
        <stop offset={0.935} stopColor="#FF5280" />
        <stop offset={1} stopColor="#FF7061" />
      </radialGradient>
    </defs>
  </svg>
))
SvgLogoMessengerOnLight.displayName = 'SvgLogoMessengerOnLight'
const Memo = memo(SvgLogoMessengerOnLight)
export { Memo as SvgLogoMessengerOnLight }
