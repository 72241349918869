// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgJoyTokenPrimary32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14ZM6 16c0 5.523 4.477 10 10 10s10-4.477 10-10h-4a6 6 0 0 1-12 0H6Z"
      fill="url(#paint0_linear_911_324)"
    />
    <defs>
      <linearGradient id="paint0_linear_911_324" x1={16} y1={2} x2={16} y2={30} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7174FF" />
        <stop offset={1} stopColor="#2823A0" />
      </linearGradient>
    </defs>
  </svg>
))
SvgJoyTokenPrimary32.displayName = 'SvgJoyTokenPrimary32'
const Memo = memo(SvgJoyTokenPrimary32)
export { Memo as SvgJoyTokenPrimary32 }
