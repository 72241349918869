// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarToken = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_1641_4312)">
      <path
        d="M11.061 2.043A10 10 0 0 1 13 21.95v-4.033a6.002 6.002 0 0 0 4.192-2.908l-1.73-1.003A4.001 4.001 0 1 1 15.463 10l1.732-1A6 6 0 0 0 13 6.085V4h-2v2.084a6.002 6.002 0 0 0-5 5.911 6 6 0 0 0 5 5.921v6.042a12.002 12.002 0 0 0 12.943-13.08A11.998 11.998 0 0 0 6.387 1.39 12 12 0 0 0 9 23.62v-2.08a10 10 0 0 1 2.061-19.497Z"
        fill="#F4F6F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1641_4312">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgSidebarToken.displayName = 'SvgSidebarToken'
const Memo = memo(SvgSidebarToken)
export { Memo as SvgSidebarToken }
