// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsEmbed = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.293 7.707 19.586 12l-4.293 4.293 1.414 1.414 5-5 .707-.707-.707-.707-5-5-1.414 1.414Zm-6.586 8.586L4.414 12l4.293-4.293-1.414-1.414-5 5-.707.707.707.707 5 5 1.414-1.414Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsEmbed.displayName = 'SvgControlsEmbed'
const Memo = memo(SvgControlsEmbed)
export { Memo as SvgControlsEmbed }
