// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionMoney = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.137 4.002a5.058 5.058 0 0 0-1.685.235c-.51.167-.89.4-1.13.64-.238.234-.312.441-.32.588-.01.142.035.332.224.556.047.056.205.193.519.36.29.156.653.307 1.054.424l2.892.678.008.002c.226.057.701.19 1.172.363.236.087.49.192.72.31.202.105.496.276.711.53.466.553.739 1.235.693 1.971-.045.731-.396 1.378-.909 1.886-.509.504-1.18.877-1.915 1.119a7.057 7.057 0 0 1-2.36.334 6.825 6.825 0 0 1-2.32-.457c-.71-.28-1.346-.695-1.803-1.242a2.887 2.887 0 0 1-.517-.838c-.132-.347-.17-.7-.167-1.074l2 .012c-.002.234.024.318.037.354.014.038.046.101.181.264.195.232.527.475 1.005.665a4.83 4.83 0 0 0 1.636.316 5.062 5.062 0 0 0 1.685-.235c.51-.167.89-.4 1.131-.64.238-.234.31-.441.32-.588.008-.136-.032-.315-.2-.527a1.24 1.24 0 0 0-.128-.073 4.522 4.522 0 0 0-.492-.21c-.382-.141-.779-.253-.96-.299l-2.926-.685-.025-.007a7.623 7.623 0 0 1-1.468-.59c-.41-.22-.82-.5-1.102-.833-.466-.552-.739-1.234-.693-1.97.045-.731.396-1.378.909-1.886.509-.504 1.18-.877 1.915-1.119a7.056 7.056 0 0 1 2.36-.334c.808.021 1.607.174 2.32.457.71.28 1.346.695 1.803 1.241.19.228.386.493.517.839.132.347.17.7.167 1.074l-2-.012c.002-.234-.024-.318-.037-.354-.014-.038-.046-.102-.181-.264-.195-.232-.527-.475-1.005-.665a4.83 4.83 0 0 0-1.636-.316Z"
      fill="#F4F6F8"
    />
    <path d="M7 1h2v14H7V1Z" fill="#F4F6F8" />
  </svg>
))
SvgActionMoney.displayName = 'SvgActionMoney'
const Memo = memo(SvgActionMoney)
export { Memo as SvgActionMoney }
