// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsVideoFile = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v1h-2v2h2v8h-2v2h2v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5Zm6 1h2v2H8V6Zm6 0h-2v2h2V6Zm2 0h2v2h-2V6ZM8 16h2v2H8v-2ZM6 6H4v2h2V6ZM4 16h2v2H4v-2Zm14 0h-2v2h2v-2Zm-6 0h2v2h-2v-2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsVideoFile.displayName = 'SvgControlsVideoFile'
const Memo = memo(SvgControlsVideoFile)
export { Memo as SvgControlsVideoFile }
