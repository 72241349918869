// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoJoystream = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={19} height={24} viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_2823_377)" fill="#fff">
      <path d="m12.827.012 2.21.005-.027 10.257a9.751 9.751 0 0 1-2.512 6.508c.197-.845.298-1.71.3-2.578l.03-14.192ZM5.72 17.9a5.91 5.91 0 0 1-2.018 1.584 5.96 5.96 0 0 1-2.506.585l.666-2.18 3.858.01ZM7.072 13.973v.219a5.8 5.8 0 0 1-.346 1.963l-4.334-.012.662-2.182 4.018.012ZM16.799.022H19l-.017 6.338a9.752 9.752 0 0 1-2.515 6.508c.197-.845.297-1.71.3-2.578l.03-10.268Z" />
      <path d="m11.073 0-.038 14.197a9.793 9.793 0 0 1-2.924 6.936 9.96 9.96 0 0 1-7.01 2.862H0l.664-2.181h.442a7.75 7.75 0 0 0 5.456-2.223 7.62 7.62 0 0 0 2.278-5.396L8.87 0h2.203Z" />
    </g>
    <defs>
      <clipPath id="clip0_2823_377">
        <path fill="#fff" d="M0 0h19v24H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgLogoJoystream.displayName = 'SvgLogoJoystream'
const Memo = memo(SvgLogoJoystream)
export { Memo as SvgLogoJoystream }
