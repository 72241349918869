// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionClock = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM7 3v5.414l3.293 3.293 1.414-1.414L9 7.586V3H7Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionClock.displayName = 'SvgActionClock'
const Memo = memo(SvgActionClock)
export { Memo as SvgActionClock }
