// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgOrderedList = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M15 4V2H6v2h9ZM2 3.707l-.646.647-.708-.708L2.13 2.163A.51.51 0 0 1 3 2.524V6h1v1H1V6h1V3.707ZM1.444 9.48a1.4 1.4 0 0 1 2.212 1.716L2.363 13H4v1H1.392a.501.501 0 0 1-.407-.793l1.858-2.593a.4.4 0 0 0-.633-.491l-.437.52-.766-.642.437-.52ZM15 7v2H6V7h9ZM15 14v-2H6v2h9Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgOrderedList.displayName = 'SvgOrderedList'
const Memo = memo(SvgOrderedList)
export { Memo as SvgOrderedList }
