// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionUnlocked = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5a4 4 0 0 1 7.874-1H9.732A2 2 0 0 0 6 5v2h7a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V5Zm8 8V9H4v4h8Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionUnlocked.displayName = 'SvgActionUnlocked'
const Memo = memo(SvgActionUnlocked)
export { Memo as SvgActionUnlocked }
