// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsVideo = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
      fill="#F4F6F8"
    />
    <path d="m10 9 5 3-5 3V9Z" fill="#F4F6F8" />
  </svg>
))
SvgControlsVideo.displayName = 'SvgControlsVideo'
const Memo = memo(SvgControlsVideo)
export { Memo as SvgControlsVideo }
