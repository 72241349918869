// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsFullScreen = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 15v4h4v2H5a2 2 0 0 1-2-2v-4h2Zm16 0v4a2 2 0 0 1-2 2h-4v-2h4v-4h2ZM3 9V5a2 2 0 0 1 2-2h4v2H5v4H3Zm16 0V5h-4V3h4a2 2 0 0 1 2 2v4h-2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsFullScreen.displayName = 'SvgControlsFullScreen'
const Memo = memo(SvgControlsFullScreen)
export { Memo as SvgControlsFullScreen }
