// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsCaptionsOutline = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5Zm20 0H3v14h18V5ZM5 9a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9V9H7v6h2v-2h2v2a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V9Zm10-2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2h-2v2h-2V9h2v2h2V9a2 2 0 0 0-2-2h-2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsCaptionsOutline.displayName = 'SvgControlsCaptionsOutline'
const Memo = memo(SvgControlsCaptionsOutline)
export { Memo as SvgControlsCaptionsOutline }
