// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarSettings = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_603_344)" fillRule="evenodd" clipRule="evenodd" fill="#F4F6F8">
      <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z" />
      <path d="M10.883 0a2 2 0 0 0-1.985 1.752l-.236 1.891c-.8.321-1.548.756-2.224 1.29L4.676 4.19a2 2 0 0 0-2.51.842L1.05 6.968a2 2 0 0 0 .525 2.594l1.52 1.15a8.888 8.888 0 0 0 0 2.575l-1.52 1.15a2 2 0 0 0-.525 2.595l1.118 1.936a2 2 0 0 0 2.51.842l1.761-.743a8.982 8.982 0 0 0 2.224 1.29l.236 1.891A2 2 0 0 0 10.883 24h2.234a2 2 0 0 0 1.985-1.752l.236-1.891a8.984 8.984 0 0 0 2.224-1.29l1.762.743a2 2 0 0 0 2.51-.842l1.117-1.936a2 2 0 0 0-.525-2.594l-1.52-1.151c.062-.425.093-.855.094-1.285v-.004c0-.43-.032-.86-.094-1.285l1.52-1.15a2 2 0 0 0 .525-2.595l-1.118-1.936a2 2 0 0 0-2.509-.842l-1.762.743a8.986 8.986 0 0 0-2.224-1.29l-.236-1.891A2 2 0 0 0 13.117 0h-2.234ZM2.78 7.968 3.9 6.032l2.92 1.233.474-.434a6.984 6.984 0 0 1 2.59-1.504l.608-.194L10.883 2h2.234l.392 3.133.608.194a6.984 6.984 0 0 1 2.59 1.504l.473.434 2.921-1.232 1.118 1.935-2.521 1.909.137.625c.109.492.164.994.165 1.498a7.031 7.031 0 0 1-.165 1.498l-.137.626 2.52 1.908-1.117 1.936-2.92-1.233-.474.434a6.983 6.983 0 0 1-2.59 1.504l-.608.194L13.117 22h-2.234l-.392-3.133-.608-.194a6.983 6.983 0 0 1-2.59-1.504l-.473-.434-2.921 1.233-1.118-1.936 2.523-1.91-.14-.626a6.888 6.888 0 0 1 0-2.992l.14-.627-2.523-1.91Z" />
    </g>
    <defs>
      <clipPath id="clip0_603_344">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgSidebarSettings.displayName = 'SvgSidebarSettings'
const Memo = memo(SvgSidebarSettings)
export { Memo as SvgSidebarSettings }
