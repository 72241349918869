// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionArrowBottom = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M7 11.172V1h2v10.172l4.293-4.293 1.414 1.414L8 15 1.293 8.293 2.707 6.88 7 11.172Z" fill="#F4F6F8" />
  </svg>
))
SvgActionArrowBottom.displayName = 'SvgActionArrowBottom'
const Memo = memo(SvgActionArrowBottom)
export { Memo as SvgActionArrowBottom }
