// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionCancel = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm5-7a5 5 0 0 1-7.757 4.172l6.929-6.93C12.695 6.034 13 6.982 13 8Zm-9.172 2.757 6.93-6.929a5 5 0 0 0-6.929 6.929Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionCancel.displayName = 'SvgActionCancel'
const Memo = memo(SvgActionCancel)
export { Memo as SvgActionCancel }
