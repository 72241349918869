// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarFollowingAlt = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M1 3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v10h-2V3H3v18h7.5v2H3a2 2 0 0 1-2-2V3Z" fill="#F4F6F8" />
    <path d="M7 13h10v2H7v-2ZM17 9H7v2h10V9Z" fill="#F4F6F8" />
  </svg>
))
SvgSidebarFollowingAlt.displayName = 'SvgSidebarFollowingAlt'
const Memo = memo(SvgSidebarFollowingAlt)
export { Memo as SvgSidebarFollowingAlt }
