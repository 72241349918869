// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsPlaceholder = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M10 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM15 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM12 15a3 3 0 0 1-3-3H7a5 5 0 0 0 10 0h-2a3 3 0 0 1-3 3Z"
      fill="#F4F6F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsPlaceholder.displayName = 'SvgControlsPlaceholder'
const Memo = memo(SvgControlsPlaceholder)
export { Memo as SvgControlsPlaceholder }
