// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesHowtoAndStyle = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.535 9-3.703-5.555-1.664 1.11 3.703 5.554a2 2 0 0 0 1.664.891H18c1.306 0 2.418.835 2.83 2H3v-2.53c1.646 1.159 4.161.692 5.109-1.392L8.144 9H9V7H7.783a1.44 1.44 0 0 0-1.31.844l-.185.406c-.508 1.117-2.092 1.121-2.606.007C3.05 6.89 1 7.34 1 8.847V17a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2v-3a5 5 0 0 0-5-5h-3.465ZM3 15v2h18v-2H3Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesHowtoAndStyle.displayName = 'SvgCategoriesHowtoAndStyle'
const Memo = memo(SvgCategoriesHowtoAndStyle)
export { Memo as SvgCategoriesHowtoAndStyle }
