// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarYpp = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M23.498 3.81C23.998 5.816 24 10 24 10s0 4.185-.502 6.19c-.02.08-.043.16-.07.239l-1.695-1.696c.098-.732.165-1.623.207-2.487A54.419 54.419 0 0 0 22 10.04V9.96a25.853 25.853 0 0 0-.008-.608 54.64 54.64 0 0 0-.053-1.599c-.063-1.303-.184-2.669-.381-3.458l-9.819 2.863L16 10l-6 4V6l1.739 1.16 9.819-2.864c-.117-.466-.437-.75-.733-.836h-.001c-.247-.071-.875-.162-1.858-.24a68.788 68.788 0 0 0-3.043-.154A137.544 137.544 0 0 0 12.08 3h-.16a87.972 87.972 0 0 0-1.1.008c-.723.009-1.705.025-2.743.058-1.04.033-2.12.083-3.043.155-.983.077-1.611.168-1.858.239h-.001c-.297.085-.616.37-.733.836-.197.788-.319 2.154-.382 3.458A54.49 54.49 0 0 0 2 9.96v.08a30.065 30.065 0 0 0 .007.608c.008.41.023.975.053 1.598.063 1.304.185 2.67.382 3.457.116.467.436.752.733.837h.001c.247.071.875.162 1.858.24.923.072 2.002.12 3.043.154a137.837 137.837 0 0 0 3.607.065h.236l6.557.001-2.292-2.293 1.414-1.414L22.306 18l-4.707 4.707-1.414-1.414L18.478 19H12s-7.505 0-9.376-.538C1.59 18.166.778 17.296.502 16.19 0 14.185 0 10 0 10s0-4.185.502-6.19C.778 2.705 1.59 1.835 2.624 1.539 4.494 1 12 1 12 1s7.506 0 9.376.538c1.034.296 1.846 1.166 2.122 2.273Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgSidebarYpp.displayName = 'SvgSidebarYpp'
const Memo = memo(SvgSidebarYpp)
export { Memo as SvgSidebarYpp }
