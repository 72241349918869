// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgItalic = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M13 2v2H9.82l-1.6 8H11v2H3v-2h3.18l1.6-8H5V2h8Z" fill="#F4F6F8" />
  </svg>
))
SvgItalic.displayName = 'SvgItalic'
const Memo = memo(SvgItalic)
export { Memo as SvgItalic }
