// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgTierIcon6 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={37} height={37} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.962 19.41V18h1.41l16.59 16.59V36h-1.41L.962 19.41Zm18 12.27v-2.814L8.095 18H5.282l13.68 13.68Zm0-8.41v2.83l-8.1-8.1h2.83l5.27 5.27Zm0-5.27h-2.52l2.52 2.52V18Zm-4.32 18h-2.814L.962 25.134V22.32L14.642 36Zm-8.41 0h2.83l-8.1-8.1v2.83L6.232 36Zm-5.27-2.52L3.482 36H.962v-2.52Z"
      fill="#AF33E9"
    />
    <g clipPath="url(#clip0_4782_70886)">
      <path d="M1.007 18C1.267 8.18 9.18.267 19 .007V18H1.007Z" fill="#AF33E9" />
    </g>
    <g clipPath="url(#clip1_4782_70886)">
      <path d="M19 .007C28.82.267 36.733 8.18 36.993 18H19V.007Z" fill="#AF33E9" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.41 36.038H19v-1.41l16.59-16.59H37v1.41l-16.59 16.59Zm12.27-18h-2.814L19 28.905v2.813l13.68-13.68Zm-8.41 0h2.83l-8.1 8.1v-2.83l5.27-5.27Zm-5.27 0v2.52l2.52-2.52H19Zm18 4.32v2.814L26.134 36.038H23.32L37 22.358Zm0 8.41v-2.83l-8.1 8.1h2.83l5.27-5.27Zm-2.52 5.27 2.52-2.52v2.52h-2.52Z"
      fill="#AF33E9"
    />
    <defs>
      <clipPath id="clip0_4782_70886">
        <path fill="#fff" transform="rotate(-90 9.5 8.5)" d="M0 0h18v18H0z" />
      </clipPath>
      <clipPath id="clip1_4782_70886">
        <path fill="#fff" transform="translate(19)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgTierIcon6.displayName = 'SvgTierIcon6'
const Memo = memo(SvgTierIcon6)
export { Memo as SvgTierIcon6 }
