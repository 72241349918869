// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionCheck = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.951 10.537 6.296-7.196 1.506 1.317-7.704 8.805-3.756-3.756 1.414-1.414 2.244 2.244Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionCheck.displayName = 'SvgActionCheck'
const Memo = memo(SvgActionCheck)
export { Memo as SvgActionCheck }
