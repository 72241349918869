// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionReupload = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m9.774 5.792 1.438-1.624a5 5 0 1 1-6.747 7.368L3.05 12.95a7 7 0 1 0 9.487-10.28l1.212-1.372L8.017.233l1.757 5.56ZM3.738 2.447a7.036 7.036 0 0 0-1.291 1.29l1.586 1.22c.266-.347.577-.658.924-.925L3.738 2.447ZM1.06 7.087a7.058 7.058 0 0 0 0 1.826l1.982-.259a5.058 5.058 0 0 1 0-1.308L1.06 7.087Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionReupload.displayName = 'SvgActionReupload'
const Memo = memo(SvgActionReupload)
export { Memo as SvgActionReupload }
