// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionLikeOutline = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6h3a2 2 0 0 1 1.857 2.743l-2 5A2 2 0 0 1 11 15H4V6l2.442-4.07A1.915 1.915 0 0 1 10 2.915V6Zm-4 7V6.554l2-3.333V8h5l-2 5H6ZM3 7H1v8h2V7Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionLikeOutline.displayName = 'SvgActionLikeOutline'
const Memo = memo(SvgActionLikeOutline)
export { Memo as SvgActionLikeOutline }
