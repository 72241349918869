// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoGithubMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.01c-5.525 0-10 4.476-10 10a9.994 9.994 0 0 0 6.838 9.488c.5.088.687-.212.687-.475 0-.237-.013-1.025-.013-1.862-2.512.462-3.162-.613-3.362-1.175-.113-.288-.6-1.175-1.025-1.413-.35-.187-.85-.65-.013-.662.788-.013 1.35.725 1.538 1.025.9 1.512 2.338 1.087 2.912.825.088-.65.35-1.088.638-1.338-2.225-.25-4.55-1.112-4.55-4.937 0-1.088.387-1.988 1.025-2.688-.1-.25-.45-1.275.1-2.65 0 0 .837-.262 2.75 1.025a9.28 9.28 0 0 1 2.5-.337c.85 0 1.7.112 2.5.337 1.912-1.3 2.75-1.025 2.75-1.025.55 1.375.2 2.4.1 2.65.637.7 1.025 1.588 1.025 2.688 0 3.837-2.337 4.687-4.562 4.937.362.313.675.913.675 1.85 0 1.338-.013 2.413-.013 2.75 0 .263.188.575.688.475A10.017 10.017 0 0 0 22 12.011c0-5.525-4.475-10-10-10Z"
      fill="#fff"
    />
  </svg>
))
SvgLogoGithubMonochrome.displayName = 'SvgLogoGithubMonochrome'
const Memo = memo(SvgLogoGithubMonochrome)
export { Memo as SvgLogoGithubMonochrome }
