// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionAuction = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M6.768 2.389c.233-.445.431-.91.591-1.389l4.2 4.2a9.376 9.376 0 0 0-2.547 1.31l5.326 4.734a2.15 2.15 0 1 1-3.034 3.035L6.57 8.953A9.378 9.378 0 0 0 5.26 11.5l-4.2-4.2a9.376 9.376 0 0 0 3.664-2.265l.37-.37A9.377 9.377 0 0 0 6.768 2.39Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionAuction.displayName = 'SvgActionAuction'
const Memo = memo(SvgActionAuction)
export { Memo as SvgActionAuction }
