// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIconRankBronzeColor = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={144}
    height={144}
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72 72H0V0h77.64L144 66.36V72h-.026C142.931 111.28 111.28 142.93 72 143.973 32.72 142.931 1.07 111.28.027 72H72Zm0-10.08L82.08 72h11L72 50.92v11Zm0-22.32L104.4 72h11.066L72 28.535V39.6Zm0-22.32L126.72 72h11.64L72 5.64v11.64Z"
      fill="url(#paint0_radial_2642_489)"
    />
    <path d="M100.534 0H89.28L144 54.72V43.465L100.534 0Z" fill="url(#paint1_radial_2642_489)" />
    <path d="M111.6 0h11.32L144 21.08V32.4L111.6 0Z" fill="url(#paint2_radial_2642_489)" />
    <path d="M133.92 0 144 10.08V0h-10.08Z" fill="url(#paint3_radial_2642_489)" />
    <defs>
      <radialGradient
        id="paint0_radial_2642_489"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71) scale(80.9256 185.213)"
      >
        <stop stopColor="#C77D6E" />
        <stop offset={0.672} stopColor="#834C3F" />
        <stop offset={1} stopColor="#603930" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2642_489"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71) scale(80.9256 185.213)"
      >
        <stop stopColor="#C77D6E" />
        <stop offset={0.672} stopColor="#834C3F" />
        <stop offset={1} stopColor="#603930" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2642_489"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71) scale(80.9256 185.213)"
      >
        <stop stopColor="#C77D6E" />
        <stop offset={0.672} stopColor="#834C3F" />
        <stop offset={1} stopColor="#603930" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_2642_489"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71) scale(80.9256 185.213)"
      >
        <stop stopColor="#C77D6E" />
        <stop offset={0.672} stopColor="#834C3F" />
        <stop offset={1} stopColor="#603930" />
      </radialGradient>
    </defs>
  </svg>
))
SvgIconRankBronzeColor.displayName = 'SvgIconRankBronzeColor'
const Memo = memo(SvgIconRankBronzeColor)
export { Memo as SvgIconRankBronzeColor }
