// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionTokensStack = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M8.7 7.46c.682.302 1.784.54 3.3.54 1.516 0 2.618-.238 3.3-.54.577-.254.7-.46.7-.46h2c0 1.131-.961 1.879-1.89 2.29-1.015.448-2.413.71-4.11.71-1.697 0-3.095-.262-4.11-.71C6.962 8.879 6 8.13 6 7h2s.123.206.7.46Z"
      fill="#F4F6F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8c0-3.314-4.477-6-10-6S2 4.686 2 8v8c0 1.909 1.38 3.427 3.15 4.41C6.954 21.412 9.377 22 12 22c2.623 0 5.046-.588 6.85-1.59C20.62 19.427 22 17.909 22 16V8Zm-2 0c0 .678-.469 1.634-1.958 2.528C16.592 11.398 14.462 12 12 12c-2.461 0-4.593-.603-6.042-1.472C4.468 9.634 4 8.678 4 8c0-.678.469-1.634 1.958-2.528C7.408 4.602 9.538 4 12 4c2.461 0 4.593.603 6.042 1.472C19.532 6.366 20 7.322 20 8Zm0 3.6c-1.825 1.458-4.729 2.4-8 2.4s-6.176-.942-8-2.4v.4c0 .853.634 1.835 2.122 2.661C7.576 15.47 9.652 16 12 16c2.348 0 4.424-.53 5.878-1.339C19.366 13.835 20 12.853 20 12v-.4ZM4 16v-.354c.355.282.742.537 1.15.764C6.954 17.412 9.377 18 12 18c2.623 0 5.046-.588 6.85-1.59a8.425 8.425 0 0 0 1.15-.764V16c0 .853-.634 1.835-2.122 2.661C16.424 19.47 14.348 20 12 20c-2.348 0-4.424-.53-5.878-1.339C4.634 17.835 4 16.853 4 16Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionTokensStack.displayName = 'SvgActionTokensStack'
const Memo = memo(SvgActionTokensStack)
export { Memo as SvgActionTokensStack }
