// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoTwitterOnLight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M22 5.925a8.217 8.217 0 0 1-2.356.645 4.114 4.114 0 0 0 1.804-2.27c-.793.47-1.671.812-2.606.995a4.104 4.104 0 0 0-6.992 3.744A11.652 11.652 0 0 1 3.393 4.75a4.104 4.104 0 0 0 1.27 5.479 4.093 4.093 0 0 1-1.859-.513v.053a4.105 4.105 0 0 0 3.292 4.023 4.132 4.132 0 0 1-1.853.07 4.106 4.106 0 0 0 3.833 2.85 8.234 8.234 0 0 1-5.097 1.756c-.33 0-.658-.019-.979-.057a11.605 11.605 0 0 0 6.29 1.844c7.548 0 11.674-6.252 11.674-11.675 0-.176-.004-.354-.012-.53a8.342 8.342 0 0 0 2.046-2.123L22 5.925Z"
      fill="#1DA1F2"
    />
  </svg>
))
SvgLogoTwitterOnLight.displayName = 'SvgLogoTwitterOnLight'
const Memo = memo(SvgLogoTwitterOnLight)
export { Memo as SvgLogoTwitterOnLight }
