// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionLikeSolid = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M8.362 1a.638.638 0 0 0-.548.31L5 6v9h6a2 2 0 0 0 1.857-1.257l2-5A2 2 0 0 0 13 6H9V1.638A.638.638 0 0 0 8.362 1ZM1 7h3v8H1V7Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionLikeSolid.displayName = 'SvgActionLikeSolid'
const Memo = memo(SvgActionLikeSolid)
export { Memo as SvgActionLikeSolid }
