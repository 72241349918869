// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionLogOut = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M1 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4H5v2h4v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3ZM9 9V7h2.586l-1.293-1.293 1.414-1.414L15.414 8l-3.707 3.707-1.414-1.414L11.586 9H9Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionLogOut.displayName = 'SvgActionLogOut'
const Memo = memo(SvgActionLogOut)
export { Memo as SvgActionLogOut }
