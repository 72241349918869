// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsCalendar = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4V1H4v3H3a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1V1h-2v3H6ZM3 8v13h18V8H3Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsCalendar.displayName = 'SvgControlsCalendar'
const Memo = memo(SvgControlsCalendar)
export { Memo as SvgControlsCalendar }
