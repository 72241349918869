// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgAlertsLoader32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_603_277)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2C8.268 2 2 8.268 2 16s6.268 14 14 14 14-6.268 14-14S23.732 2 16 2ZM0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16Z"
        fill="#DAE2EB"
      />
      <path
        d="M6.259 26.055A13.954 13.954 0 0 0 15.999 30c7.733 0 14-6.268 14-14S23.733 2 16 2V0c8.838 0 16 7.163 16 16s-7.162 16-16 16a15.947 15.947 0 0 1-11.132-4.508l1.392-1.437Z"
        fill="#4038FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_603_277">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgAlertsLoader32.displayName = 'SvgAlertsLoader32'
const Memo = memo(SvgAlertsLoader32)
export { Memo as SvgAlertsLoader32 }
