// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesPetsAndAnimals = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.625 2.22-1.027-.822-.517 1.208L12.34 9H6a3 3 0 0 1-3-3H1a4.998 4.998 0 0 0 2.505 4.334A2.986 2.986 0 0 0 3 12v11h2V12a1 1 0 0 1 1-1h7.66l.26-.606L16.4 4.602l1.474 1.179.274.219h2.718a3 3 0 0 1-1.918 1.963l-.265.088-.598.2-.078.625-1 8-.008.062V21h4v-2h-2v-1.938l.916-7.327A5 5 0 0 0 23 5.117V4h-4.15l-2.225-1.78ZM7 20v3h2v-3.288l1.489-3.35L13 17.618V23h2v-6.618l-.553-.276-4-2-.936-.468-.425.956-2 4.5-.086.194V20Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesPetsAndAnimals.displayName = 'SvgCategoriesPetsAndAnimals'
const Memo = memo(SvgCategoriesPetsAndAnimals)
export { Memo as SvgCategoriesPetsAndAnimals }
