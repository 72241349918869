// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoTelegramOnLight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M12 22.01c5.523 0 10-4.476 10-10 0-5.522-4.477-10-10-10s-10 4.478-10 10c0 5.524 4.477 10 10 10Z"
      fill="url(#paint0_linear_603_319)"
    />
    <path
      d="M6.527 11.905c2.915-1.27 4.859-2.107 5.831-2.512 2.777-1.155 3.354-1.355 3.73-1.362.083-.002.268.019.388.116a.421.421 0 0 1 .142.271c.014.078.03.255.017.394-.15 1.58-.802 5.418-1.133 7.19-.14.749-.416 1-.683 1.024-.58.054-1.022-.383-1.584-.752-.88-.577-1.378-.936-2.232-1.5-.987-.65-.347-1.007.215-1.592.148-.153 2.706-2.48 2.756-2.691.006-.027.012-.125-.047-.177-.058-.052-.144-.034-.207-.02-.088.02-1.494.95-4.218 2.788-.399.274-.76.408-1.084.4-.357-.007-1.044-.201-1.555-.367-.626-.204-1.124-.312-1.08-.657.022-.18.27-.364.744-.553Z"
      fill="#fff"
    />
    <defs>
      <linearGradient id="paint0_linear_603_319" x1={12} y1={2.011} x2={12} y2={21.863} gradientUnits="userSpaceOnUse">
        <stop stopColor="#2AABEE" />
        <stop offset={1} stopColor="#229ED9" />
      </linearGradient>
    </defs>
  </svg>
))
SvgLogoTelegramOnLight.displayName = 'SvgLogoTelegramOnLight'
const Memo = memo(SvgLogoTelegramOnLight)
export { Memo as SvgLogoTelegramOnLight }
