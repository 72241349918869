// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionAddChannel = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M11.874 15H4.126a4.002 4.002 0 0 1 7.748 0Z" fill="#F4F6F8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2Zm5 1h2v2.5h2.5v2H9V9H7V6.5H4.5v-2H7V2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionAddChannel.displayName = 'SvgActionAddChannel'
const Memo = memo(SvgActionAddChannel)
export { Memo as SvgActionAddChannel }
