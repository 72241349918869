// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIconRankGoldMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={144}
    height={144}
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M143.973 71.974C142.931 32.694 111.28 1.042 72 0 32.72 1.042 1.069 32.694.026 71.974H0v10.08l10.08-10.08h11L0 93.054v11.32l32.4-32.4h11.065L0 115.439v11.255l54.72-54.72h11.64L0 138.334v5.64h5.64L72 77.614l66.36 66.36H144v-5.641l-66.36-66.36H72h71.973Z"
      fill="#fff"
    />
    <path d="M28.535 143.974H17.28L72 89.254l54.72 54.72h-11.254L72 100.508l-43.465 43.466Z" fill="#fff" />
    <path d="M39.6 143.974h11.32L72 122.894l21.08 21.08h11.32l-32.4-32.4-32.4 32.4Z" fill="#fff" />
    <path
      d="M61.92 143.974 72 133.894l10.08 10.08H61.92ZM144 115.439v11.255l-54.72-54.72h11.254L144 115.439ZM144 104.374v-11.32l-21.08-21.08H111.6l32.4 32.4ZM144 71.974v10.08l-10.08-10.08H144Z"
      fill="#fff"
    />
  </svg>
))
SvgIconRankGoldMonochrome.displayName = 'SvgIconRankGoldMonochrome'
const Memo = memo(SvgIconRankGoldMonochrome)
export { Memo as SvgIconRankGoldMonochrome }
