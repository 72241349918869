// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionDownload = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M2 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1h-2v1H4v-1H2v1ZM11.293 3.879 9 6.172V1.586H7v4.586L4.707 3.879 3.293 5.293 8 10l4.707-4.707-1.414-1.414Z"
      fill="#F4F6F8"
    />
    <path
      d="M11.293 3.879 9 6.172V1.586H7v4.586L4.707 3.879 3.293 5.293 8 10l4.707-4.707-1.414-1.414Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionDownload.displayName = 'SvgActionDownload'
const Memo = memo(SvgActionDownload)
export { Memo as SvgActionDownload }
