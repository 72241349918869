// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgJoyTokenPrimary16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM3 8a5 5 0 0 0 10 0h-2a3 3 0 0 1-6 0H3Z"
      fill="url(#paint0_linear_911_332)"
    />
    <defs>
      <linearGradient id="paint0_linear_911_332" x1={8} y1={1} x2={8} y2={15} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7174FF" />
        <stop offset={1} stopColor="#2823A0" />
      </linearGradient>
    </defs>
  </svg>
))
SvgJoyTokenPrimary16.displayName = 'SvgJoyTokenPrimary16'
const Memo = memo(SvgJoyTokenPrimary16)
export { Memo as SvgJoyTokenPrimary16 }
