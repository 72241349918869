// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgAlertsWarning32 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_603_274)" fill="#CAA802">
      <path d="M17 10v8h-2v-8h2ZM17 22v-2h-2v2h2Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16ZM16 2C8.268 2 2 8.268 2 16s6.268 14 14 14 14-6.268 14-14S23.732 2 16 2Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_603_274">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgAlertsWarning32.displayName = 'SvgAlertsWarning32'
const Memo = memo(SvgAlertsWarning32)
export { Memo as SvgAlertsWarning32 }
