// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionChannel = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2Zm7 6a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      fill="#F4F6F8"
    />
    <path d="M8 12a4.002 4.002 0 0 0-3.874 3h7.748A4.002 4.002 0 0 0 8 12Z" fill="#F4F6F8" />
  </svg>
))
SvgActionChannel.displayName = 'SvgActionChannel'
const Memo = memo(SvgActionChannel)
export { Memo as SvgActionChannel }
