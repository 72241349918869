// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionBuyNow = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3Zm5 5a2 2 0 0 1-2-2H4a4 4 0 1 0 8 0h-2a2 2 0 0 1-2 2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionBuyNow.displayName = 'SvgActionBuyNow'
const Memo = memo(SvgActionBuyNow)
export { Memo as SvgActionBuyNow }
