// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsCastSolid = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3a2 2 0 0 0-2 2v6h2V5h18v14H11v2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H3ZM1 15a6 6 0 0 1 6 6h2a8 8 0 0 0-8-8v2Zm2 6a2 2 0 0 0-2-2v-2a4 4 0 0 1 4 4H3Zm2-10v.832A10.037 10.037 0 0 1 10.168 17H19V7H5v4Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsCastSolid.displayName = 'SvgControlsCastSolid'
const Memo = memo(SvgControlsCastSolid)
export { Memo as SvgControlsCastSolid }
