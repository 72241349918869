// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionDislikeOutline = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10H3a2 2 0 0 1-1.857-2.743l2-5A2 2 0 0 1 5 1h7v9l-2.442 4.07A1.915 1.915 0 0 1 6 13.084V10Zm4-7v6.446l-2 3.333V8H3l2-5h5Zm3 6h2V1h-2v8Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionDislikeOutline.displayName = 'SvgActionDislikeOutline'
const Memo = memo(SvgActionDislikeOutline)
export { Memo as SvgActionDislikeOutline }
