// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionHeartOutline = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m8 4.65-.663-.588-.347-.307a3 3 0 0 0-3.98 4.49s0 0 0 0 0 0 0 0l4.991 4.42 4.99-4.42L8 4.65Zm0 0 .664-.588M8 4.651l.664-.589m0 0 .345-.306m-.345.306.345-.306m0 0a3 3 0 0 1 4.236.253m-4.236-.253 4.236.253m0 0a3 3 0 0 1-.254 4.235l.254-4.235Z"
      stroke="#F4F6F8"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
))
SvgActionHeartOutline.displayName = 'SvgActionHeartOutline'
const Memo = memo(SvgActionHeartOutline)
export { Memo as SvgActionHeartOutline }
