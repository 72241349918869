// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsShare = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m20 12-8-6v4h-2c-4 0-6 2-6 5v1h2v-1a1 1 0 0 1 1-1h5v4l8-6Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsShare.displayName = 'SvgControlsShare'
const Memo = memo(SvgControlsShare)
export { Memo as SvgControlsShare }
