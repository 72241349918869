// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionTrash = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M10 1H6L5 2H3a1 1 0 0 0-1 1v1h12V3a1 1 0 0 0-1-1h-2l-1-1ZM3 6h10v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionTrash.displayName = 'SvgActionTrash'
const Memo = memo(SvgActionTrash)
export { Memo as SvgActionTrash }
