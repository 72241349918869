// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsShareAlt = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.707 2.293 12 1.586l-.707.707-3 3L7.586 6 9 7.414l.707-.707L11 5.414V16h2V5.414l1.293 1.293.707.707L16.414 6l-.707-.707-3-3ZM5 12a2 2 0 0 1 2-2h2v2H7v8h10v-8h-2v-2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-8Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsShareAlt.displayName = 'SvgControlsShareAlt'
const Memo = memo(SvgControlsShareAlt)
export { Memo as SvgControlsShareAlt }
