// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoYoutubeWhiteFull = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={110} height={24} viewBox="0 0 110 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_2208_4388)" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.395.522a4.39 4.39 0 0 1 3.088 3.088c.738 2.73.76 8.39.76 8.39s0 5.683-.738 8.39a4.39 4.39 0 0 1-3.088 3.088c-2.707.738-13.604.738-13.604.738s-10.896 0-13.603-.738a4.39 4.39 0 0 1-3.088-3.088C.384 17.66.384 12 .384 12s0-5.66.716-8.368A4.39 4.39 0 0 1 4.187.544c2.708-.738 13.604-.76 13.604-.76s10.896 0 13.604.738ZM14.323 6.764 23.363 12l-9.04 5.236V6.764Z"
      />
      <path d="M50.95 22.001c-.693-.47-1.186-1.185-1.477-2.17-.29-.984-.425-2.282-.425-3.915V13.7c0-1.633.157-2.975.493-3.96.335-1.007.85-1.723 1.566-2.17.716-.448 1.633-.694 2.774-.694 1.12 0 2.036.224 2.708.694.67.47 1.186 1.186 1.499 2.17.313.985.47 2.305.47 3.938v2.215c0 1.633-.157 2.931-.47 3.916-.313.984-.806 1.7-1.5 2.17-.693.448-1.633.694-2.796.694-1.208.022-2.148-.224-2.842-.672Zm3.871-2.394c.201-.492.29-1.32.29-2.438v-4.766c0-1.097-.089-1.902-.29-2.394-.201-.515-.537-.761-1.007-.761-.47 0-.783.246-.984.76-.202.515-.291 1.298-.291 2.395v4.766c0 1.118.09 1.946.268 2.438.18.493.515.739 1.007.739.47 0 .806-.246 1.007-.739Zm49.045-2.864.09 2.216c.067.492.179.85.358 1.073.179.224.47.336.85.336.515 0 .873-.201 1.052-.604.201-.403.29-1.074.313-1.991l2.976.179c.022.134.022.313.022.537 0 1.41-.38 2.46-1.164 3.154-.783.694-1.857 1.052-3.266 1.052-1.701 0-2.887-.537-3.558-1.589-.671-1.051-1.029-2.707-1.029-4.922v-2.707c0-2.283.358-3.96 1.052-5.012.716-1.052 1.924-1.589 3.624-1.589 1.186 0 2.081.224 2.708.65.626.424 1.074 1.095 1.342 2.013.269.917.38 2.17.38 3.781v2.618h-5.75v.805Zm.448-7.227c-.179.224-.291.56-.358 1.052s-.09 1.23-.09 2.238v1.096h2.506v-1.096c0-.985-.022-1.723-.089-2.238-.067-.515-.179-.873-.358-1.074-.179-.201-.448-.313-.806-.313-.38.022-.649.134-.805.335ZM42.582 15.67 38.667 1.53h3.423l1.365 6.399c.358 1.588.604 2.93.783 4.027h.09c.111-.805.38-2.125.782-4.005l1.41-6.421h3.423l-3.96 14.118v6.78h-3.378v-6.758h-.023ZM69.655 7.167V22.45h-2.684l-.291-1.88h-.068c-.738 1.41-1.834 2.126-3.289 2.126-1.006 0-1.767-.336-2.237-1.007-.492-.671-.716-1.7-.716-3.11V7.168h3.446v11.209c0 .671.067 1.163.223 1.454.157.291.403.425.739.425.29 0 .581-.09.85-.268.269-.18.47-.425.604-.694V7.167h3.423ZM87.309 7.167V22.45h-2.685l-.29-1.88h-.068c-.738 1.41-1.835 2.126-3.289 2.126-1.007 0-1.768-.336-2.237-1.007-.493-.671-.716-1.7-.716-3.11V7.168h3.445v11.209c0 .671.067 1.163.224 1.454.157.291.403.425.738.425.291 0 .582-.09.85-.268.27-.18.47-.425.605-.694V7.167h3.423Z" />
      <path d="M79.008 4.303h-3.423V22.45h-3.356V4.303h-3.424V1.53h10.18v2.774h.023Zm19.667 5.303c-.201-.962-.537-1.656-1.007-2.103-.47-.425-1.096-.65-1.924-.65-.626 0-1.23.18-1.767.538-.56.358-.963.828-1.276 1.41h-.022V.745h-3.311v21.68h2.841l.358-1.454h.067c.269.515.671.918 1.186 1.23.542.298 1.15.452 1.768.448 1.163 0 2.013-.537 2.573-1.61.537-1.075.828-2.753.828-5.013v-2.416c0-1.7-.112-3.043-.314-4.005Zm-3.154 6.242c0 1.12-.045 1.992-.135 2.618-.09.627-.246 1.074-.47 1.343a1.083 1.083 0 0 1-.872.402c-.291 0-.537-.067-.783-.2-.246-.135-.425-.337-.582-.605v-8.681c.112-.425.313-.761.604-1.03.29-.268.582-.402.917-.402.358 0 .627.134.806.402.201.269.313.739.403 1.388.067.649.111 1.566.111 2.774v1.991Z" />
    </g>
    <defs>
      <clipPath id="clip0_2208_4388">
        <path fill="#fff" d="M0 0h110v24H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgLogoYoutubeWhiteFull.displayName = 'SvgLogoYoutubeWhiteFull'
const Memo = memo(SvgLogoYoutubeWhiteFull)
export { Memo as SvgLogoYoutubeWhiteFull }
