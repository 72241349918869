// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionNewDraftTab = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M15 3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h2v-2H3V5h10v8h-2v2h2a2 2 0 0 0 2-2V3Z"
      fill="#F4F6F8"
    />
    <path
      d="M5.707 11.707 7 10.414V15h2v-4.586l1.293 1.293 1.414-1.414L8 6.586l-3.707 3.707 1.414 1.414Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionNewDraftTab.displayName = 'SvgActionNewDraftTab'
const Memo = memo(SvgActionNewDraftTab)
export { Memo as SvgActionNewDraftTab }
