// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionDrag = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 6H1V4h14v2ZM15 12H1v-2h14v2Z" fill="#F4F6F8" />
  </svg>
))
SvgActionDrag.displayName = 'SvgActionDrag'
const Memo = memo(SvgActionDrag)
export { Memo as SvgActionDrag }
