// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionBulb = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M13 6a5.001 5.001 0 0 1-3 4.584V12H6v-1.416A5.001 5.001 0 0 1 8 1a5 5 0 0 1 5 5ZM8 15a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionBulb.displayName = 'SvgActionBulb'
const Memo = memo(SvgActionBulb)
export { Memo as SvgActionBulb }
