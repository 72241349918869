// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionInfo = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M12 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM11 18v-5h-1v-2h3v7h-2Z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
      fill="#fff"
    />
  </svg>
))
SvgActionInfo.displayName = 'SvgActionInfo'
const Memo = memo(SvgActionInfo)
export { Memo as SvgActionInfo }
