// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesFilmAndAnimation = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2h-2v4h2V2Zm-1 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 0 1 7.465-2H20a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2v2h-2v-2H8v2H6v-2H4a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h4v2H4v6h16v-6h-2a4 4 0 0 1-8 0Zm-2 2H6v2h2v-2ZM6.222 5.636l1.414-1.414 2.828 2.828L9.05 8.464 6.222 5.636Zm15.557 0-1.415-1.414-2.828 2.828 1.414 1.414 2.829-2.828Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesFilmAndAnimation.displayName = 'SvgCategoriesFilmAndAnimation'
const Memo = memo(SvgCategoriesFilmAndAnimation)
export { Memo as SvgCategoriesFilmAndAnimation }
