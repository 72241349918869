// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesEducation = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9.477v2.446l-2-.8V8a1 1 0 0 1 .629-.928l10-4L12 2.923l.371.149 10 4a1 1 0 0 1 0 1.856L19 10.277v5.969a3 3 0 0 1-1.886 2.785l-4.743 1.897-.371.149-.371-.149-4.743-1.897A3 3 0 0 1 5 16.246v-3.569l2 .8v2.768a1 1 0 0 0 .629.929L12 18.923l4.371-1.749a1 1 0 0 0 .629-.928v-5.169l-4.629 1.851-.371.149-.371-.149L3 9.477Zm9-4.4L19.307 8 12 10.923 4.693 8 12 5.077Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesEducation.displayName = 'SvgCategoriesEducation'
const Memo = memo(SvgCategoriesEducation)
export { Memo as SvgCategoriesEducation }
