// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoWhatsAppMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.002 4.916a9.817 9.817 0 0 0-7.01-2.908c-5.459 0-9.906 4.447-9.911 9.907 0 1.749.456 3.45 1.32 4.956L2 22.008l5.255-1.378a9.917 9.917 0 0 0 4.738 1.207h.005c5.46 0 9.907-4.448 9.912-9.912a9.873 9.873 0 0 0-2.908-7.01Zm-7.01 15.243c-1.482 0-2.931-.399-4.195-1.15l-.3-.18-3.116.817.831-3.041-.195-.314a8.2 8.2 0 0 1-1.259-4.38c0-4.534 3.697-8.23 8.24-8.23a8.2 8.2 0 0 1 5.825 2.413 8.196 8.196 0 0 1 2.41 5.826c-.005 4.547-3.702 8.24-8.24 8.24Zm4.52-6.167c-.247-.124-1.464-.723-1.692-.808-.228-.081-.394-.124-.556.123-.166.247-.641.808-.784.97-.142.166-.29.185-.537.062-.247-.124-1.045-.385-1.99-1.231-.737-.656-1.231-1.469-1.379-1.716-.142-.247-.014-.38.11-.503.109-.11.247-.29.37-.433.124-.142.166-.247.247-.413.081-.166.043-.309-.019-.432-.062-.124-.556-1.345-.765-1.84-.2-.484-.404-.418-.556-.422-.142-.01-.309-.01-.475-.01a.911.911 0 0 0-.66.309c-.228.247-.865.846-.865 2.067s.888 2.395 1.012 2.561c.123.167 1.744 2.666 4.229 3.74.59.256 1.05.409 1.411.523.594.19 1.131.161 1.559.1.475-.072 1.463-.6 1.672-1.18.205-.579.205-1.073.143-1.178-.062-.104-.228-.166-.475-.29Z"
      fill="#fff"
    />
  </svg>
))
SvgLogoWhatsAppMonochrome.displayName = 'SvgLogoWhatsAppMonochrome'
const Memo = memo(SvgLogoWhatsAppMonochrome)
export { Memo as SvgLogoWhatsAppMonochrome }
