// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesSports = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 7.669A4.977 4.977 0 0 1 16 7c.91 0 1.765.244 2.5.669a6.973 6.973 0 0 0-1.5 4.33c0 1.636.561 3.14 1.5 4.332-.735.425-1.589.669-2.5.669a4.976 4.976 0 0 1-2.5-.67A6.973 6.973 0 0 0 15 12c0-1.635-.561-3.14-1.5-4.331ZM12 8.999c-.628.836-1 1.875-1 3.001s.372 2.165 1 3c.629-.836 1-1.874 1-3a4.975 4.975 0 0 0-1-3Zm7 3c0-1.125.371-2.164 1-3 .628.836 1 1.875 1 3.001a4.978 4.978 0 0 1-1 3 4.975 4.975 0 0 1-1-3ZM16 5a7 7 0 1 0 0 14 7 7 0 0 0 0-14ZM8 7H4v2h4V7ZM4 17v-2h4v2H4Zm3-4v-2H1v2h6Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesSports.displayName = 'SvgCategoriesSports'
const Memo = memo(SvgCategoriesSports)
export { Memo as SvgCategoriesSports }
