// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionSpeech = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 15h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h4l6 3v-3Zm-5.528-2H8V4h12v9h-4v1.764L12.472 13Z"
      fill="#F4F6F8"
    />
    <path d="M6 19H4a2 2 0 0 1-2-2V7h2v10h4v1.764L11.528 17H14v2h-2l-6 3v-3Z" fill="#F4F6F8" />
  </svg>
))
SvgActionSpeech.displayName = 'SvgActionSpeech'
const Memo = memo(SvgActionSpeech)
export { Memo as SvgActionSpeech }
