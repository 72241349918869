// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgBlockquote = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M1 14h2V2H1v12ZM13 4V2H6v2h7ZM15 7v2H6V7h9ZM13 14v-2H6v2h7Z" fill="#F4F6F8" />
  </svg>
))
SvgBlockquote.displayName = 'SvgBlockquote'
const Memo = memo(SvgBlockquote)
export { Memo as SvgBlockquote }
