// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesMusic = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4.22a2 2 0 0 0-2.392-1.961l-8 1.6A2 2 0 0 0 8 5.819v8.716a4 4 0 1 0 1.985 3.812H10V9.819l6-1.2V6.58l-6 1.2V5.82l8-1.6V12.535A4 4 0 1 0 20 16V4.22ZM18 16a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM8 18a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesMusic.displayName = 'SvgCategoriesMusic'
const Memo = memo(SvgCategoriesMusic)
export { Memo as SvgCategoriesMusic }
