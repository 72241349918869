// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionCouncil = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M2 4a2 2 0 0 0 2 2c0-1.309.628-2.47 1.6-3.2A2 2 0 0 0 2 4ZM4 7c.042 0 .085 0 .127.002.098.381.251.74.45 1.07A5.993 5.993 0 0 0 2 13H1a1 1 0 0 1-1-1v-1a4 4 0 0 1 4-4ZM4 13c0-1.506.832-2.818 2.062-3.5.574.319 1.235.5 1.938.5.703 0 1.364-.181 1.938-.5A3.999 3.999 0 0 1 12 13v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1ZM14 13a5.993 5.993 0 0 0-2.577-4.929c.199-.328.352-.688.45-1.069A4 4 0 0 1 16 11v1a1 1 0 0 1-1 1h-1ZM14 4a2 2 0 0 1-2 2c0-1.309-.628-2.47-1.6-3.2A2 2 0 0 1 14 4ZM8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionCouncil.displayName = 'SvgActionCouncil'
const Memo = memo(SvgActionCouncil)
export { Memo as SvgActionCouncil }
