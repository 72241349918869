// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIconRankSilverMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={144}
    height={144}
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0 0h144v80H72v-8H0V0ZM72 136h72v8H72v-8ZM72 88h72v8H72v-8ZM72 120h72v8H72v-8ZM72 104h72v8H72v-8Z"
      fill="#fff"
    />
    <path d="M8 72v72H0V72h8ZM72 72v72h-8V72h8ZM56 72v72h-8V72h8ZM24 72v72h-8V72h8ZM40 72v72h-8V72h8Z" fill="#fff" />
  </svg>
))
SvgIconRankSilverMonochrome.displayName = 'SvgIconRankSilverMonochrome'
const Memo = memo(SvgIconRankSilverMonochrome)
export { Memo as SvgIconRankSilverMonochrome }
