// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsNewTab = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M22 10V2h-8v2h4.586l-8.293 8.293 1.414 1.414L20 5.414V10h2Z" fill="#F4F6F8" />
    <path d="M4 20h12v-7h2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7v2H4v12Z" fill="#F4F6F8" />
  </svg>
))
SvgControlsNewTab.displayName = 'SvgControlsNewTab'
const Memo = memo(SvgControlsNewTab)
export { Memo as SvgControlsNewTab }
