// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarVideos = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 1H5v2h14V1Zm-4 13-5-3v6l5-3ZM3 5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H3Zm0 2h18v14H3V7Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgSidebarVideos.displayName = 'SvgSidebarVideos'
const Memo = memo(SvgSidebarVideos)
export { Memo as SvgSidebarVideos }
