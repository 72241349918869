// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgBold = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2h5a3.5 3.5 0 0 1 2.984 5.33A3.5 3.5 0 0 1 9.5 14H3V2Zm2 10h4.5a1.5 1.5 0 0 0 0-3H5v3Zm0-5h3a1.5 1.5 0 1 0 0-3H5v3Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgBold.displayName = 'SvgBold'
const Memo = memo(SvgBold)
export { Memo as SvgBold }
