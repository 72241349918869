// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgAlertsWarning24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-9-4v5h-2V8h2Zm-1 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="#CAA802"
    />
  </svg>
))
SvgAlertsWarning24.displayName = 'SvgAlertsWarning24'
const Memo = memo(SvgAlertsWarning24)
export { Memo as SvgAlertsWarning24 }
