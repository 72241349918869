// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarFollowing = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M10.207 3.864c.733-1.486 2.853-1.486 3.586 0l1.961 3.972 4.385.637c1.64.238 2.295 2.254 1.108 3.411l-2.265 2.208-1.396-1.432 2.265-2.208-4.385-.637a2 2 0 0 1-1.505-1.094L12 4.75l-1.96 3.972a2 2 0 0 1-1.506 1.094l-4.385.637 3.173 3.093a2 2 0 0 1 .575 1.77l-.749 4.367 3.272-1.72.93 1.77-3.272 1.72c-1.467.772-3.181-.474-2.901-2.108l.749-4.367-3.173-3.093c-1.187-1.157-.532-3.173 1.108-3.41l4.385-.638 1.96-3.972ZM13 20h11v2H13v-2Z"
      fill="#F4F6F8"
    />
    <path d="M24 16H13v2h11v-2Z" fill="#F4F6F8" />
  </svg>
))
SvgSidebarFollowing.displayName = 'SvgSidebarFollowing'
const Memo = memo(SvgSidebarFollowing)
export { Memo as SvgSidebarFollowing }
