// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsConnect = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M10 6a6 6 0 0 1 0 12v2a8.001 8.001 0 0 0 7.938-7H23v-2h-5.062A8.001 8.001 0 0 0 10 4v2Z" fill="#F4F6F8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16a4.002 4.002 0 0 1-3.874-3H1v-2h5.126A4.002 4.002 0 0 1 14 12a4 4 0 0 1-4 4Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsConnect.displayName = 'SvgControlsConnect'
const Memo = memo(SvgControlsConnect)
export { Memo as SvgControlsConnect }
