// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIllustrativeReupload = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m29.84 7.43 2.271 5.37 1.28-1.754A16 16 0 1 1 12.352 34.97a16.003 16.003 0 0 1-1.687-2.127l-1.667 1.105a17.999 17.999 0 0 0 4.493 4.667 18.035 18.035 0 0 0 2.801 1.652 18.052 18.052 0 0 0 6.258 1.675 17.993 17.993 0 0 0 3.253-.032 17.71 17.71 0 0 0 1.61-.236A18 18 0 0 0 34.57 9.431l1.078-1.477-5.807-.524ZM14.935 8.45c.468-.273.949-.525 1.442-.756l.848 1.812c-.44.205-.867.43-1.282.671L14.934 8.45Zm-4.918 4.216a18.004 18.004 0 0 1 2.268-2.331l1.302 1.518a16.003 16.003 0 0 0-2.016 2.073l-1.554-1.26ZM7.49 16.83c.217-.5.455-.988.715-1.462l1.755.959a16.017 16.017 0 0 0-1.152 2.652L6.91 18.35c.17-.514.364-1.021.58-1.52Zm-1.474 7.938a18.004 18.004 0 0 1 .156-3.25l1.98.276c-.132.957-.179 1.924-.137 2.888l-1.999.085Zm.864 4.793a17.999 17.999 0 0 1-.432-1.57l1.95-.443a16.002 16.002 0 0 0 .893 2.75l-1.838.787a17.998 17.998 0 0 1-.573-1.524Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgIllustrativeReupload.displayName = 'SvgIllustrativeReupload'
const Memo = memo(SvgIllustrativeReupload)
export { Memo as SvgIllustrativeReupload }
