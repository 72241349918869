// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsPrevious = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="m9 12 11 7V5L9 12Zm-5 7h2V5H4v14Z" fill="#F4F6F8" />
  </svg>
))
SvgControlsPrevious.displayName = 'SvgControlsPrevious'
const Memo = memo(SvgControlsPrevious)
export { Memo as SvgControlsPrevious }
