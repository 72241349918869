// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgJoyTokenPrimary24 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-.004-6a4 4 0 0 1-4-4h-3a7 7 0 1 0 14 0h-3a4 4 0 0 1-4 4Z"
      fill="url(#paint0_linear_911_329)"
    />
    <defs>
      <linearGradient id="paint0_linear_911_329" x1={12} y1={2} x2={12} y2={22} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7174FF" />
        <stop offset={1} stopColor="#2823A0" />
      </linearGradient>
    </defs>
  </svg>
))
SvgJoyTokenPrimary24.displayName = 'SvgJoyTokenPrimary24'
const Memo = memo(SvgJoyTokenPrimary24)
export { Memo as SvgJoyTokenPrimary24 }
