// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsLink = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 9a2 2 0 0 1 2-2h7v2H3v6h7v2H3a2 2 0 0 1-2-2V9Zm22 6a2 2 0 0 1-2 2h-7v-2h7V9h-7V7h7a2 2 0 0 1 2 2v6ZM6 13h12v-2H6v2Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsLink.displayName = 'SvgControlsLink'
const Memo = memo(SvgControlsLink)
export { Memo as SvgControlsLink }
