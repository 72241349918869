// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoLinkedInMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.507a1.504 1.504 0 0 1 1.504-1.504h14.992A1.504 1.504 0 0 1 21 4.507v14.992a1.504 1.504 0 0 1-1.504 1.504H4.504A1.504 1.504 0 0 1 3 19.5V4.507Zm7.125 5.359h2.438v1.224c.35-.704 1.251-1.337 2.604-1.337 2.593 0 3.208 1.402 3.208 3.974v4.764H15.75v-4.178c0-1.465-.351-2.291-1.245-2.291-1.24 0-1.755.89-1.755 2.29v4.179h-2.625V9.866Zm-4.5 8.512H8.25V9.753H5.625v8.625Zm3-11.437a1.688 1.688 0 1 1-3.375 0 1.688 1.688 0 0 1 3.375 0Z"
      fill="#fff"
    />
  </svg>
))
SvgLogoLinkedInMonochrome.displayName = 'SvgLogoLinkedInMonochrome'
const Memo = memo(SvgLogoLinkedInMonochrome)
export { Memo as SvgLogoLinkedInMonochrome }
