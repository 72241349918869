// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionShow = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13c5 0 7-5 7-5s-2-5-7-5-7 5-7 5 2 5 7 5Zm0-3a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionShow.displayName = 'SvgActionShow'
const Memo = memo(SvgActionShow)
export { Memo as SvgActionShow }
