// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionDislikeSolid = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M7.638 15a.639.639 0 0 0 .548-.31L11 10V1H5a2 2 0 0 0-1.857 1.257l-2 5A2 2 0 0 0 3 10h4v4.361c0 .353.286.639.638.639ZM15 9h-3V1h3v8Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionDislikeSolid.displayName = 'SvgActionDislikeSolid'
const Memo = memo(SvgActionDislikeSolid)
export { Memo as SvgActionDislikeSolid }
