// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsSearchAlt = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2a9 9 0 1 0 5.618 16.032l3.675 3.675 1.414-1.414-3.675-3.675A9 9 0 0 0 11 2Zm-7 9a7 7 0 1 1 14 0 7 7 0 0 1-14 0Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsSearchAlt.displayName = 'SvgControlsSearchAlt'
const Memo = memo(SvgControlsSearchAlt)
export { Memo as SvgControlsSearchAlt }
