// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgJoyTokenSilver48 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Zm-.008-12a8 8 0 0 1-8-8h-6c0 7.732 6.268 14 14 14s14-6.268 14-14h-6a8 8 0 0 1-8 8Z"
      fill="url(#paint0_linear_911_334)"
    />
    <defs>
      <linearGradient id="paint0_linear_911_334" x1={24} y1={4} x2={24} y2={44} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7B8A95" />
        <stop offset={1} stopColor="#424E57" />
      </linearGradient>
    </defs>
  </svg>
))
SvgJoyTokenSilver48.displayName = 'SvgJoyTokenSilver48'
const Memo = memo(SvgJoyTokenSilver48)
export { Memo as SvgJoyTokenSilver48 }
