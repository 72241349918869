// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsZoomIn = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M11 9h3v2h-3v3H9v-3H6V9h3V6h2v3Z" fill="#F4F6F8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm-7 9a7 7 0 1 1 14 0 7 7 0 0 1-14 0Z"
      fill="#F4F6F8"
    />
    <path d="m22.707 21.293-4-4-1.414 1.414 4 4 1.414-1.414Z" fill="#F4F6F8" />
  </svg>
))
SvgControlsZoomIn.displayName = 'SvgControlsZoomIn'
const Memo = memo(SvgControlsZoomIn)
export { Memo as SvgControlsZoomIn }
