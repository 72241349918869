// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionRead = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.535a1 1 0 0 1 .445-.832l5-3.333a1 1 0 0 1 1.11 0l5 3.333a1 1 0 0 1 .445.832V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6.535Zm5.39 5.257a1 1 0 0 0 1.22 0l3.2-2.466a1 1 0 1 0-1.22-1.584L8 9.737 5.41 7.742a1 1 0 1 0-1.22 1.584l3.2 2.466Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionRead.displayName = 'SvgActionRead'
const Memo = memo(SvgActionRead)
export { Memo as SvgActionRead }
