// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgCategoriesAutosAndVehicles = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.547 6.725A1 1 0 0 1 8.51 6h7.05a1 1 0 0 1 .948.684l.544 1.632.228.684H20.5V7h-1.78l-.316-.949A3 3 0 0 0 15.56 4h-7.05a3 3 0 0 0-2.885 2.176L4.246 11H3a2 2 0 0 0-2 2v4a3 3 0 0 0 3 3h.93a3 3 0 0 0 2.496-1.336L8.536 17h6.929l1.11 1.664A3 3 0 0 0 19.07 20H20a3 3 0 0 0 3-3v-4a2 2 0 0 0-2-2H6.326l1.221-4.275ZM3 13v4a1 1 0 0 0 1 1h.93a1 1 0 0 0 .832-.445l1.11-1.664A2 2 0 0 1 8.534 15h6.93a2 2 0 0 1 1.664.89l1.11 1.665a1 1 0 0 0 .831.445H20a1 1 0 0 0 1-1v-4H3Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgCategoriesAutosAndVehicles.displayName = 'SvgCategoriesAutosAndVehicles'
const Memo = memo(SvgCategoriesAutosAndVehicles)
export { Memo as SvgCategoriesAutosAndVehicles }
