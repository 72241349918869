// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsSettingsSolid = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.586 3h-1.172L9.65 1.236l-.56.153c-.896.246-1.746.601-2.535 1.052l-.505.287v2.494l-.828.828H2.728l-.287.505c-.45.789-.806 1.639-1.052 2.535l-.153.56L3 11.414v1.172L1.236 14.35l.153.56c.246.896.601 1.747 1.052 2.535l.287.505h2.494l.828.828v2.494l.505.288c.789.45 1.639.806 2.535 1.05l.56.154L11.414 21h1.172l1.764 1.764.56-.153a10.94 10.94 0 0 0 2.535-1.051l.505-.288v-2.494l.828-.828h2.494l.288-.505c.45-.788.806-1.639 1.05-2.535l.154-.56L21 12.586v-1.172l1.764-1.764-.153-.56c-.245-.896-.601-1.746-1.051-2.535l-.288-.505h-2.494l-.828-.828V2.728l-.505-.287c-.788-.45-1.639-.806-2.535-1.052l-.56-.153L12.586 3ZM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsSettingsSolid.displayName = 'SvgControlsSettingsSolid'
const Memo = memo(SvgControlsSettingsSolid)
export { Memo as SvgControlsSettingsSolid }
