// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgStrikethrough = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M8.455 3H7.364A2.364 2.364 0 0 0 5 5.364C5 6.267 5.733 7 6.636 7H14v1H2V7h1.388A3.621 3.621 0 0 1 3 5.364 4.364 4.364 0 0 1 7.364 1h1.09a4.546 4.546 0 0 1 4.514 4H10.94a2.546 2.546 0 0 0-2.486-2ZM8.636 13h-1.09a2.546 2.546 0 0 1-2.488-2H3.032c.27 2.253 2.188 4 4.513 4h1.091A4.364 4.364 0 0 0 13 10.636 3.62 3.62 0 0 0 12.612 9H9.363C10.267 9 11 9.733 11 10.636A2.364 2.364 0 0 1 8.636 13Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgStrikethrough.displayName = 'SvgStrikethrough'
const Memo = memo(SvgStrikethrough)
export { Memo as SvgStrikethrough }
