// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgControlsCancel = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10 8A8 8 0 0 1 5.68 7.094L16.906 18.32A7.966 7.966 0 0 1 12 20Zm6.32-3.094A8 8 0 0 0 7.094 5.68L18.32 16.906Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgControlsCancel.displayName = 'SvgControlsCancel'
const Memo = memo(SvgControlsCancel)
export { Memo as SvgControlsCancel }
