// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoYoutube = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={46} height={32} viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M44.8 5A5.734 5.734 0 0 0 40.738.956C37.168 0 22.867 0 22.867 0S8.56 0 5 .956A5.726 5.726 0 0 0 .956 5C0 8.565 0 16.008 0 16.008s0 7.442.956 11.008A5.726 5.726 0 0 0 5 31.058C8.565 32 22.867 32 22.867 32s14.306 0 17.87-.956a5.734 5.734 0 0 0 4.063-4.029c.953-3.565.953-11.008.953-11.008S45.732 8.566 44.8 5ZM18.293 22.867V9.148l11.885 6.86-11.885 6.859Z"
      fill="#7B8A95"
    />
  </svg>
))
SvgLogoYoutube.displayName = 'SvgLogoYoutube'
const Memo = memo(SvgLogoYoutube)
export { Memo as SvgLogoYoutube }
