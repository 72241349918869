// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionWarning = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.857 2.486a1 1 0 0 0-1.714 0l-6 10A1 1 0 0 0 2 14h12a1 1 0 0 0 .857-1.514l-6-10ZM9 10V5H7v5h2Zm0 3v-2H7v2h2Z"
      fill="#fff"
    />
  </svg>
))
SvgActionWarning.displayName = 'SvgActionWarning'
const Memo = memo(SvgActionWarning)
export { Memo as SvgActionWarning }
