// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionSettings = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M12.86 6.03c-.104-.301-.13-.656.017-.938l.723-1.38-1.225-1.225-1.378.722c-.283.149-.626.125-.93.026a.93.93 0 0 1-.644-.59L8.875 1h-1.75l-.56 1.608a.849.849 0 0 1-.535.532c-.301.104-.656.13-.938-.017L3.712 2.4 2.4 3.713l.723 1.38c.148.281.12.636.017.937a.849.849 0 0 1-.532.536L1 7.125v1.75l1.566.545c.279.097.49.328.606.6.029.067.058.134.085.202a.73.73 0 0 1-.05.61L2.4 12.376 3.625 13.6l1.378-.722c.283-.148.626-.124.93-.025l.036.01a.93.93 0 0 1 .611.571L7.125 15h1.75l.545-1.566c.097-.278.328-.49.6-.606l.202-.085a.73.73 0 0 1 .61.05l1.543.807 1.225-1.225-.722-1.378c-.148-.283-.124-.626-.025-.93l.01-.036a.93.93 0 0 1 .571-.611L15 8.875v-1.75l-1.608-.56a.849.849 0 0 1-.532-.535ZM8 10.625c-1.487 0-2.625-1.137-2.625-2.625 0-1.487 1.138-2.625 2.625-2.625 1.488 0 2.625 1.138 2.625 2.625 0 1.488-1.137 2.625-2.625 2.625Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionSettings.displayName = 'SvgActionSettings'
const Memo = memo(SvgActionSettings)
export { Memo as SvgActionSettings }
