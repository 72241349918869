// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarNft = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M17 12 9 7v10l8-5Z" fill="#F4F6F8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.915 4.863 12 0 3.085 4.863A4 4 0 0 0 1 8.374v7.251a4 4 0 0 0 2.085 3.512L12 24l8.915-4.863A4 4 0 0 0 23 15.625v-7.25a4 4 0 0 0-2.085-3.512ZM3 15.625v-7.25a2 2 0 0 1 1.042-1.756L12 2.279l7.958 4.34A2 2 0 0 1 21 8.374v7.251a2 2 0 0 1-1.042 1.756L12 21.721l-7.958-4.34A2 2 0 0 1 3 15.625Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgSidebarNft.displayName = 'SvgSidebarNft'
const Memo = memo(SvgSidebarNft)
export { Memo as SvgSidebarNft }
