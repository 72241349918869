// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionEdit = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m12.414 6.414 2-2-2.828-2.828-2 2 2.828 2.828ZM10.414 8.414 4.828 14H2v-2.828l5.586-5.586 2.828 2.828Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionEdit.displayName = 'SvgActionEdit'
const Memo = memo(SvgActionEdit)
export { Memo as SvgActionEdit }
