// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLogoInstagramOnLight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M6.107 3.474A4.414 4.414 0 0 0 4.51 4.513a4.401 4.401 0 0 0-1.039 1.592c-.223.57-.373 1.225-.417 2.184C3.01 9.248 3 9.555 3 12c0 2.445.011 2.752.055 3.711.044.959.197 1.614.417 2.185.23.593.538 1.094 1.04 1.595a4.414 4.414 0 0 0 1.595 1.04c.57.223 1.226.373 2.184.417.959.044 1.266.055 3.71.055 2.445 0 2.753-.011 3.711-.055.96-.044 1.614-.198 2.185-.418a4.414 4.414 0 0 0 1.595-1.039 4.413 4.413 0 0 0 1.04-1.595c.223-.571.373-1.226.417-2.185.044-.959.055-1.266.055-3.71 0-2.445-.011-2.752-.055-3.71-.044-.96-.198-1.615-.417-2.185a4.438 4.438 0 0 0-1.036-1.592 4.414 4.414 0 0 0-1.595-1.04c-.571-.223-1.226-.373-2.185-.417-.959-.043-1.266-.054-3.71-.054-2.445 0-2.752.01-3.711.054-.962.04-1.617.194-2.188.418Zm9.532 1.2c.878.04 1.354.186 1.672.31.421.166.721.36 1.036.674.315.315.509.615.673 1.036.125.318.271.794.311 1.672.044.948.052 1.233.052 3.637 0 2.405-.011 2.69-.052 3.638-.04.878-.186 1.354-.31 1.672-.165.42-.36.72-.674 1.036a2.804 2.804 0 0 1-1.036.673c-.318.124-.794.27-1.672.31-.948.045-1.233.052-3.637.052s-2.69-.01-3.638-.051c-.878-.04-1.353-.187-1.672-.311a2.805 2.805 0 0 1-1.035-.673 2.804 2.804 0 0 1-.674-1.036c-.124-.318-.27-.794-.31-1.672-.045-.948-.052-1.233-.052-3.638 0-2.404.011-2.69.051-3.637.04-.878.187-1.354.311-1.672.165-.421.359-.721.674-1.036a2.804 2.804 0 0 1 1.035-.673c.319-.125.794-.27 1.672-.311.948-.044 1.234-.051 3.638-.051s2.69.007 3.637.05Z"
      fill="url(#paint0_radial_603_398)"
    />
    <path
      d="M7.38 12.004a4.622 4.622 0 1 0 9.244 0 4.622 4.622 0 0 0-9.244 0Zm7.622 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      fill="url(#paint1_radial_603_398)"
    />
    <path d="M16.81 8.279a1.08 1.08 0 1 0 0-2.16 1.08 1.08 0 0 0 0 2.16Z" fill="#654C9F" />
    <defs>
      <radialGradient
        id="paint0_radial_603_398"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(25.99333 -1.3623 1.15794 22.09408 3.328 19.354)"
      >
        <stop stopColor="#FED576" />
        <stop offset={0.263} stopColor="#F47133" />
        <stop offset={0.609} stopColor="#BC3081" />
        <stop offset={1} stopColor="#4C63D2" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_603_398"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-3 305.028 -136.226) scale(13.3662 11.3612)"
      >
        <stop stopColor="#FED576" />
        <stop offset={0.263} stopColor="#F47133" />
        <stop offset={0.609} stopColor="#BC3081" />
        <stop offset={1} stopColor="#4C63D2" />
      </radialGradient>
    </defs>
  </svg>
))
SvgLogoInstagramOnLight.displayName = 'SvgLogoInstagramOnLight'
const Memo = memo(SvgLogoInstagramOnLight)
export { Memo as SvgLogoInstagramOnLight }
