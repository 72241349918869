// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionShieldLock = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.733 2.948C3.31 3 2.873 3 2 3v3.675c0 .534 0 .801.015 1.043a8 8 0 0 0 5.001 6.939c.225.09.478.174.984.343.506-.169.76-.253.984-.343a8 8 0 0 0 5.001-6.94C14 7.477 14 7.21 14 6.676V3c-.872 0-1.309 0-1.733-.052a7 7 0 0 1-2.292-.694c-.382-.192-.745-.434-1.471-.918L8 1l-.504.336c-.726.484-1.089.726-1.471.918a7 7 0 0 1-2.292.694ZM10 6a2 2 0 0 1-1 1.732V12H7V7.732A2 2 0 1 1 10 6Z"
      fill="#fff"
    />
  </svg>
))
SvgActionShieldLock.displayName = 'SvgActionShieldLock'
const Memo = memo(SvgActionShieldLock)
export { Memo as SvgActionShieldLock }
