// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionHeartSolid = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.007 3.347a4 4 0 0 1 5.646-.34L8 3.314l.346-.307a4 4 0 0 1 5.308 5.986L8.001 14 2.347 8.993a4 4 0 0 1-.34-5.646Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionHeartSolid.displayName = 'SvgActionHeartSolid'
const Memo = memo(SvgActionHeartSolid)
export { Memo as SvgActionHeartSolid }
