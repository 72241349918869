// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionLoader = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M8 15a6.978 6.978 0 0 1-4.95-2.05l1.415-1.414A5 5 0 1 0 8 3V1a7 7 0 0 1-.001 14ZM2.447 3.738a7.035 7.035 0 0 1 1.291-1.291l1.219 1.585a5.036 5.036 0 0 0-.924.925l-1.586-1.22ZM1 8c0-.309.02-.614.06-.913l1.982.259a5.058 5.058 0 0 0 0 1.308l-1.983.259C1.02 8.613 1 8.309 1 8Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionLoader.displayName = 'SvgActionLoader'
const Memo = memo(SvgActionLoader)
export { Memo as SvgActionLoader }
