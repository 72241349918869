// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionNewChannel = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M6 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 5v3h3v2h-3v3h-2v-3H7V8h3V5h2ZM1 12a4 4 0 0 1 4-4v4h3v2H2a1 1 0 0 1-1-1v-1Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionNewChannel.displayName = 'SvgActionNewChannel'
const Memo = memo(SvgActionNewChannel)
export { Memo as SvgActionNewChannel }
