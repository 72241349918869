// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionNewTab = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3Zm9 7h-2V7.414l-3.293 3.293-1.414-1.414L8.586 6H7V4h5v5Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionNewTab.displayName = 'SvgActionNewTab'
const Memo = memo(SvgActionNewTab)
export { Memo as SvgActionNewTab }
