// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionMenu = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M2 13h12v-2H2v2Zm0-4h12V7H2v2Zm0-6v2h12V3H2Z" fill="#F4F6F8" />
  </svg>
))
SvgActionMenu.displayName = 'SvgActionMenu'
const Memo = memo(SvgActionMenu)
export { Memo as SvgActionMenu }
