// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionSwitchMember = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8a4 4 0 0 0-4 4v1a1 1 0 0 0 1 1h6v-2H5V8ZM11.586 9 9.293 6.707l1.414-1.414L14.414 9l-3.707 3.707-1.414-1.414L11.586 9Z"
      fill="#F4F6F8"
    />
    <path d="M7 10V8h5v2H7ZM8 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" fill="#F4F6F8" />
  </svg>
))
SvgActionSwitchMember.displayName = 'SvgActionSwitchMember'
const Memo = memo(SvgActionSwitchMember)
export { Memo as SvgActionSwitchMember }
