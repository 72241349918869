// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgJoyTokenMonochrome16 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM3 8a5 5 0 0 0 10 0h-2a3 3 0 0 1-6 0H3Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgJoyTokenMonochrome16.displayName = 'SvgJoyTokenMonochrome16'
const Memo = memo(SvgJoyTokenMonochrome16)
export { Memo as SvgJoyTokenMonochrome16 }
