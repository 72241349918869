// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgPulseLineStraight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={3} height={58} viewBox="0 0 3 58" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <mask
      id="mask0_1487_14602"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={3}
      height={85}
    >
      <path d="M1.761 2v81.63" stroke="#007DFF" strokeWidth={2.148} strokeLinecap="round" />
    </mask>
    <g mask="url(#mask0_1487_14602)">
      <path fill="url(#paint0_linear_1487_14602)" d="M-12.202.926h27.926v56.926h-27.926z" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1487_14602"
        x1={1.761}
        y1={0.926}
        x2={1.761}
        y2={57.852}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007DFF" stopOpacity={0} />
        <stop offset={0.307} stopColor="#007DFF" />
        <stop offset={0.813} stopColor="#AFD5FC" />
        <stop offset={1} stopColor="#AFD5FC" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
))
SvgPulseLineStraight.displayName = 'SvgPulseLineStraight'
const Memo = memo(SvgPulseLineStraight)
export { Memo as SvgPulseLineStraight }
