// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIconRankDiamondColor = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={144}
    height={144}
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M144 71.974V63.97C109.139 62.933 81.067 34.861 80.03 0h-8.004C73.07 39.28 104.72 70.931 144 71.974Z"
      fill="url(#paint0_radial_2642_492)"
    />
    <path
      d="M88.034 0C89.065 30.442 113.557 54.935 144 55.966v-8.005C117.976 46.937 97.063 26.024 96.04 0h-8.006Z"
      fill="url(#paint1_radial_2642_492)"
    />
    <path d="M144 40c-22.091 0-40-17.909-40-40h8c0 17.673 14.327 32 32 32v8Z" fill="url(#paint2_radial_2642_492)" />
    <path d="M120 0c0 13.255 10.745 24 24 24v-8c-8.837 0-16-7.163-16-16h-8Z" fill="url(#paint3_radial_2642_492)" />
    <path d="M144 8a8 8 0 0 1-8-8h8v8Z" fill="url(#paint4_radial_2642_492)" />
    <path d="M0 8a8 8 0 0 0 8-8H0v8Z" fill="url(#paint5_radial_2642_492)" />
    <path d="M24 0c0 13.255-10.745 24-24 24v-8c8.837 0 16-7.163 16-16h8Z" fill="url(#paint6_radial_2642_492)" />
    <path
      d="M63.97 0h8.004C70.93 39.28 39.28 70.931 0 71.974V63.97C34.861 62.933 62.933 34.861 63.97 0Z"
      fill="url(#paint7_radial_2642_492)"
    />
    <path
      d="M55.966 0C54.935 30.443 30.443 54.935 0 55.966v-8.005C26.024 46.937 46.937 26.024 47.96 0h8.006Z"
      fill="url(#paint8_radial_2642_492)"
    />
    <path d="M0 40c22.091 0 40-17.909 40-40h-8c0 17.673-14.327 32-32 32v8Z" fill="url(#paint9_radial_2642_492)" />
    <path
      d="M0 72.026v8.004c34.861 1.037 62.933 29.109 63.97 63.97h8.004C70.93 104.72 39.28 73.069 0 72.026Z"
      fill="url(#paint10_radial_2642_492)"
    />
    <path
      d="M55.966 144C54.935 113.557 30.442 89.065 0 88.034v8.005C26.024 97.063 46.937 117.976 47.96 144h8.006Z"
      fill="url(#paint11_radial_2642_492)"
    />
    <path d="M0 104c22.091 0 40 17.909 40 40h-8c0-17.673-14.327-32-32-32v-8Z" fill="url(#paint12_radial_2642_492)" />
    <path d="M24 144c0-13.255-10.745-24-24-24v8c8.837 0 16 7.163 16 16h8Z" fill="url(#paint13_radial_2642_492)" />
    <path d="M0 136a8 8 0 0 1 8 8H0v-8Z" fill="url(#paint14_radial_2642_492)" />
    <path
      d="M72.026 144h8.004c1.037-34.861 29.109-62.933 63.97-63.97v-8.004C104.72 73.07 73.069 104.72 72.026 144Z"
      fill="url(#paint15_radial_2642_492)"
    />
    <path
      d="M144 88.034C113.557 89.065 89.065 113.557 88.034 144h8.005c1.024-26.024 21.937-46.937 47.961-47.96v-8.006Z"
      fill="url(#paint16_radial_2642_492)"
    />
    <path d="M104 144c0-22.091 17.909-40 40-40v8c-17.673 0-32 14.327-32 32h-8Z" fill="url(#paint17_radial_2642_492)" />
    <path d="M144 120c-13.255 0-24 10.745-24 24h8c0-8.837 7.163-16 16-16v-8Z" fill="url(#paint18_radial_2642_492)" />
    <path d="M136 144a8 8 0 0 1 8-8v8h-8Z" fill="url(#paint19_radial_2642_492)" />
    <defs>
      <radialGradient
        id="paint0_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_2642_492"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(177.482 34.457 71.013) scale(80.9256 185.247)"
      >
        <stop stopColor="#0FD4B9" />
        <stop offset={0.672} stopColor="#49C9B9" />
        <stop offset={1} stopColor="#2CAD9F" />
      </radialGradient>
    </defs>
  </svg>
))
SvgIconRankDiamondColor.displayName = 'SvgIconRankDiamondColor'
const Memo = memo(SvgIconRankDiamondColor)
export { Memo as SvgIconRankDiamondColor }
