// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgIllustrativeImage = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M5 5a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h20v-2H5V7h30v16h2V7a2 2 0 0 0-2-2H5Z" fill="#F4F6F8" />
    <path d="M25 17.5 7 27v4h18v-4a4 4 0 0 1 4-4l-4-5.5Z" fill="#F4F6F8" />
    <path
      d="M45 27a2 2 0 0 0-2-2H29a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8v-8.586l2 2L40.414 35 36 30.586 31.586 35 33 36.414l2-2V41h-6V27h14v14h-4v2h4a2 2 0 0 0 2-2V27ZM12 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgIllustrativeImage.displayName = 'SvgIllustrativeImage'
const Memo = memo(SvgIllustrativeImage)
export { Memo as SvgIllustrativeImage }
