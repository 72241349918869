// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSidebarChannel = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      fill="#F4F6F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h4v-3a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1H9v2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Zm16 20h2V3H3v18h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgSidebarChannel.displayName = 'SvgSidebarChannel'
const Memo = memo(SvgSidebarChannel)
export { Memo as SvgSidebarChannel }
