// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionSoundOn = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m3 6 2-4h2v12H5l-2-4H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1ZM13 8a4 4 0 0 0-4-4V2a6 6 0 0 1 0 12v-2a4 4 0 0 0 4-4Z"
      fill="#F4F6F8"
    />
    <path d="M11 8a2 2 0 0 0-2-2v4a2 2 0 0 0 2-2Z" fill="#F4F6F8" />
  </svg>
))
SvgActionSoundOn.displayName = 'SvgActionSoundOn'
const Memo = memo(SvgActionSoundOn)
export { Memo as SvgActionSoundOn }
