// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionSoundOff = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m13.445 12.03 1.262 1.263-1.414 1.414-12-12 1.414-1.414 1.764 1.764L5 2h2v3.586l2 2V6a2 2 0 0 1 1.606 3.192l1.422 1.422A4 4 0 0 0 9 4V2a6 6 0 0 1 4.445 10.03ZM7 10.914 2.086 6H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h1l2 4h2v-3.086Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionSoundOff.displayName = 'SvgActionSoundOff'
const Memo = memo(SvgActionSoundOff)
export { Memo as SvgActionSoundOff }
