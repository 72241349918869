// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionMember = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm0-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 6a5 5 0 0 0 4.546-2.916A5.986 5.986 0 0 0 8 8a5.986 5.986 0 0 0-4.546 2.084A5 5 0 0 0 8 13Z"
      fill="#F4F6F8"
    />
  </svg>
))
SvgActionMember.displayName = 'SvgActionMember'
const Memo = memo(SvgActionMember)
export { Memo as SvgActionMember }
