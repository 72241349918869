// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionMinimize = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path fill="#F4F6F8" d="M3 15v-2h10v2z" />
  </svg>
))
SvgActionMinimize.displayName = 'SvgActionMinimize'
const Memo = memo(SvgActionMinimize)
export { Memo as SvgActionMinimize }
