// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgActionCopy = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M6 2h8v8h-2V4H6V2Z" fill="#F4F6F8" />
    <path d="M2 7a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Z" fill="#F4F6F8" />
  </svg>
))
SvgActionCopy.displayName = 'SvgActionCopy'
const Memo = memo(SvgActionCopy)
export { Memo as SvgActionCopy }
